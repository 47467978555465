import React from "react";
import { Link } from "react-router-dom";

import AdditionalMenuPopover from "./additional-menu-popover";
import AdditionalMenuModal from "./additional-menu-modal";
import { UserRequestModal } from "components/user";
import { AvatarCustom } from "components/avatar";
import { useLogout } from "components/use-hooks";
import { Localize } from "../../service";
import Icons from "components/icons";

import "./additional-menu.css";


//import PDF from "../../../assets/MMDA_DiamondStarClub_AGB.pdf";



const AdditionalMenu = ({ type, user }) => {

    const { logout } = useLogout();
    const Wrap = ( props ) => {
         return type === "popover"? <AdditionalMenuPopover {...props }/> : <AdditionalMenuModal {...props }/>
    };


    return(
        <Wrap>
            <AvatarCustom
                photoUrl={ user.avatar }
                initials={ user.initials }
                size={ 35 }
            >
                <Link to={`/${user.nickname}`}>
                    <Localize>MENUS.Label_MyProfile</Localize>
                </Link>
                { user.diamond_status &&
                    <div className="diamond-status">
                        <Localize wrap>GLOBAL.Text_DiamondStatus</Localize> <Icons.Diamond/>
                    </div>
                }
            </AvatarCustom>
            <nav className={ `additional-menu` }>
                <ul>
                    {/*<li>*/}
                    {/*    <a*/}
                    {/*        href="https://www.mitsubishi-motors.de/serviceversprechen"*/}
                    {/*        target="_blank"*/}
                    {/*        rel="noreferrer"*/}
                    {/*        className="with-icon"*/}
                    {/*    >*/}
                    {/*        <Icons.Competition />*/}
                    {/*        <Localize wrap>MENUS.Label_Competition</Localize>*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a*/}
                    {/*        href="https://www.mitsubishi-motors.de/serviceversprechen"*/}
                    {/*        target="_blank"*/}
                    {/*        rel="noreferrer"*/}
                    {/*        className="with-icon"*/}
                    {/*    >*/}
                    {/*        <Icons.Ranking />*/}
                    {/*        <Localize wrap>MENUS.Label_Ranking</Localize>*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/*{ localStorage.getItem("jwtAuth") &&*/}
                    {/*    <li>*/}
                    {/*        <a*/}
                    {/*            href      = "https://sso.diamond-star-club.de/reise"*/}
                    {/*            target    = "_blank"*/}
                    {/*            rel       = "noreferrer"*/}
                    {/*            className = "with-icon game"*/}
                    {/*        >*/}
                    {/*            <Icons.Document />*/}
                    {/*            Diamantenreise*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*}*/}
                    <li>
                        <Link
                            to="/chat"
                            className="with-icon"
                        >
                            <Icons.Chat type="menu" />
                            <Localize wrap>MENUS.Label_Chat</Localize>
                        </Link>
                    </li>
                    <li>
                        <UserRequestModal />
                    </li>
                    <li>
                        <a
                            href     = "https://diamond-star-club.me/upload/MMDA_DiamondStarClub_AGB.pdf"
                            target   = "_blank"
                            rel      = "noreferrer"
                            className="with-icon"
                        >
                            <Icons.Document />
                            <Localize wrap>MENUS.Label_Terms</Localize>
                        </a>
                    </li>
                    <li>
                        <a
                            href     = "https://diamond-star-club.me/upload/FAQs_Mitsubishi_Diamond_Star_Club.v3.pdf"
                            target   = "_blank"
                            rel      = "noreferrer"
                            className="with-icon"
                        >
                            <Icons.Document />
                            <Localize wrap>MENUS.Label_FAQ</Localize>
                        </a>
                    </li>
                    {/*<li>*/}
                    {/*    <a*/}
                    {/*        href="https://presse.mitsubishi-motors.de/pm/mitsubishi-motors-startet-mit-attraktiven-rabatten-ins-jahr"*/}
                    {/*        target="_blank"*/}
                    {/*        rel="noreferrer"*/}
                    {/*        className="with-icon"*/}
                    {/*    >*/}
                    {/*        <Icons.Events />*/}
                    {/*        <Localize wrap>MENUS.Label_Events</Localize>*/}
                    {/*    </a>*/}
                    {/*</li>*/}

                    <li>
                        <button onClick={ logout } className="with-icon">
                            <Icons.SignOut />
                            <Localize wrap>MENUS.Button_Text_Logout</Localize>
                        </button>
                    </li>

                </ul>
            </nav>
        </Wrap>
    );
}

export default AdditionalMenu;